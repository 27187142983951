import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from "gatsby";
import Seo from '../../components/SEO/seo';
import Layout from '../../components/LayoutPlainText';
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Flow + Snelstart Partnership",
  "path": "/nl/flow-snelstart-partnership"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Flow en jouw accountant werken via SnelStart samen`}</h1>
    <p>{`Een nieuwe functie binnen Flow is de mogelijkheid om gegevens te delen met jouw accountant. Dat doen we in samenwerking met SnelStart. SnelStart is een online boekhoudsysteem.`}</p>
    <p>{`Als jouw accountant gebruik maakt van SnelStart als leverancier, dan kan jouw accountant via onze samenwerkingspartner SnelStart om jouw toestemming vragen om gegevens vanuit jouw Flow account met hem of haar te delen.`}</p>
    <p>{`Door jouw Flow gegevens te koppelen aan de SnelStart omgeving van jouw accountant, kan jouw accountant zijn dienstverlening en jouw Flows optimaliseren. Ook kan jouw accountant door toegang te krijgen tot jouw Flow gegevens zijn eigen flows met jou delen, zodat jij daar gebruik van kan maken.`}</p>
    <p>{`We delen alleen gegevens met jouw accountant als jij daarvoor toestemming geeft. De toestemming die jij daarvoor geeft, kan je altijd gemakkelijk via de Flow app weer intrekken, zodat jouw accountant niet langer de gegevens vanuit jouw Flow account kan inzien.`}</p>
    <p>{`Door jouw Flow gegevens met jouw accountant te delen, krijgt jouw accountant in zijn of haar SnelStart omgeving inzicht in jouw persoonlijke Flows, de naam van jouw rekening(en), IBAN nummers, gegevens van de tegenrekening, bedragen en transactieomschrijvingen.`}</p>
    <p>{`Uiteraard vinden we jouw privacy belangrijk. Zo worden gegevens alleen met jouw accountant gedeeld als je toestemming geeft. Jouw toestemming kan je gemakkelijk via de Flow app weer intrekken. Jouw accountant is net als Flow een zelfstandig verwerkingsverantwoordelijke en verwerkt jouw persoonsgegevens voor zijn eigen doelen en grondslagen. De verwerking van jouw persoonsgegevens vanuit jouw Flow account zal jouw accountant doen voor het hierboven omschreven doel en op basis van toestemming als grondslag.`}</p>
    <p>{`Het privacy statement van jouw accountant zal je meer vertellen over hoe jouw accountant omgaat met jouw persoonsgegevens, welke gegevens hij of zij verwerkt en voor welke doelen, maar ook over jouw rechten en hoe je die kan uitoefenen.`}</p>
    <p>{`SnelStart is als leverancier van een online boekhoudsysteem een verwerker van jouw accountant. SnelStart heeft met alle accountants waar zij mee werkt een verwerkersovereenkomst gesloten.`}</p>



    <Seo title={props.pageContext.frontmatter.title} path={props.pageContext.frontmatter.path} mdxType="Seo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      